import logo from './logo.svg';
import './App.css';
import Header from './Header.js'
import {useEffect, useState, useRef} from 'react'
import {useFormik} from 'formik'

import {
  Stack,
  Text,
  Icon,
  IconButton,
  Button,
  Box,
  Image,
  Circle,
  VStack,
  HStack,
  List,
  ListItem,
  ListIcon,
  OrderedList,
  UnorderedList,
  Flex,
  Spacer,
  Input,
  Select,
  Link,
  Hide,
  Grid,
  GridItem,
  Wrap, WrapItem, Center, FormControl, FormErrorMessage
} from '@chakra-ui/react'
import { DownloadIcon } from '@chakra-ui/icons'
import { FiPlay, FiArrowUpRight, FiArrowLeft, FiArrowRight } from 'react-icons/fi'
import { AnimationOnScroll } from 'react-animation-on-scroll';
import ScrollIntoView from 'react-scroll-into-view'
import Slider from 'react-slick';
import incG from './incG.png'
import inc from './inc.png'
import inc500G from './inc500G.png'
import inc500 from './inc500.png'
import forbesG from './forbesG.png'
import forbes from './forbes.png'
import nbfG from './nbfG.png'
import nbf from './nbf.png'
import wlG from './wlG.png'
import wl from './wl.png'
import hirepower from './hirepower.png'
import villePic1 from './villeabout.png'
import villefeatured from './villefeatured.png'
import villeTall from './villeTall.png'
import elippse from './Ellipse.png'
import vincitLogo from './vincitLogo.svg'
import linkedIn from './Social-LinkedIn-02.png'
import instagram from './Social-Instagram-02.png'
import ville2 from './ville2.jpg'
import villespeach from './villespeach.JPG'
import villelogo from './villelogo.png'
import villeinitial from './villeinitial.png'
//import tedx from './tedx.png'

function App() {
  const [offset, setOffset] = useState(0);
  const [show, setShow] = useState(false);

  function sendEmail(values) {
    fetch("/api", {
      method: "POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        name: values.name,
        email: values.email
      })
    })
    .then(res => {
      return res.json()
    })
  }

  const windowSize = useRef([window.innerWidth, window.innerHeight])

  const controlNavbar = () => {
    if (typeof window !== 'undefined') {
      if (window.pageYOffset > windowSize.current[1] - 180) {
        setShow(true);
      } else {
        setShow(false);
      }
    }
  };

  useEffect(() => {
    const onScroll = () => {
      setOffset(window.pageYOffset)
    }
    window.addEventListener('scroll', controlNavbar, { passive: true })
        return () => window.removeEventListener('scroll', onScroll)
    }, [])

    const settings = {
      dots:"true",
      autoplay:"true",
      swipeToSlide:"true",
      nextArrow: <FiArrowRight/>,
      prevArrow: <FiArrowLeft />,
      autoplaySpeed: "5000"
    }

    const validate = values => {
      const errors = {};
    
      if (!values.name) {
        errors.name = 'Required';
      }

      if (!values.email) {
        errors.email = 'Required';
      } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = 'Invalid email address';
      }
    
      return errors;
    };

    const formik = useFormik({
      initialValues: {
        email: "",
        name: ""
      },
      validate,
      onSubmit: (values, {resetForm}) => {
        sendEmail(values)
        resetForm()
      }
    })

    const downloadFiles = (e, fileName) => {
      fetch(process.env.PUBLIC_URL+fileName).then(res => {
        res.blob()
        .then(blob => {
          const fileURL = window.URL.createObjectURL(blob)
          let link = document.createElement('a')
          link.href = fileURL
          link.download = fileName
          link.click()
        })
      })
    }

  return (
    <div className="App">
      <link
        rel="stylesheet"
        type="text/css"
        charSet="UTF-8"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
      />
      <link
        rel="stylesheet"
        type="text/css"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
      />
      <Flex>
        <Flex
          as="nav"
          align="center"
          justify="space-between"
          wrap="wrap"
          width="100%"
          bg="#161517"
          height={{md:"96px", base:"72px"}}
          position="fixed"
          zIndex="20"
          paddingX={{md:"120px", base:"20px"}}
        >
          <Box w={{md:"220px", base:"160px"}} h="100%">
            <Center w="100%" h="100%">
              <ScrollIntoView selector="#root">
                <Link>
                  <Image src={villelogo}/>
                  </Link>
              </ScrollIntoView>
            </Center>
          </Box>
          <Spacer />
          <Hide below="base">
          <ScrollIntoView selector="#about">
            <Button
            height="56px"
            width="125px"
            color="#F04E30"
            variant="link"
            display={{xl:"block", base:"none"}}
            >
            About
            </Button>
          </ScrollIntoView>
          <ScrollIntoView selector="#testimonials">
            <Button
            height="56px"
            width="125px"
            color="#F04E30"
            variant="link"
            display={{xl:"block", base:"none"}}
            >
            Testimonials
            </Button>
          </ScrollIntoView>
          <ScrollIntoView selector="#portfolio">
            <Button
            height="56px"
            width="125px"
            color="#F04E30"
            variant="link"
            display={{xl:"block", base:"none"}}
            >
            Portfolio
            </Button>
          </ScrollIntoView>
          <ScrollIntoView selector="#topics">
            <Button
            height="56px"
            width="125px"
            color="#F04E30"
            variant="link"
            display={{xl:"block", base:"none"}}
            >
            Topics
            </Button>
          </ScrollIntoView>
          <ScrollIntoView selector="#connect">
            <Button
            height="56px"
            width="125px"
            color="#F04E30"
            variant="link"
            display={{xl:"block", base:"none"}}
            >
            Resources
            </Button>
          </ScrollIntoView>
          {show ?
            <ScrollIntoView selector="#contact">
              <Button
              className="active"
              height={{md:"56px", base:"48px"}}
              width={{md:"151px", base:"104px"}}
              bg="#F04E30"
              color="#FFFFFF"
              onClick={() => {this.scroll(this.myRef)}}
              >
                Contact
              </Button>
            </ScrollIntoView>

          :
          <Button
          height="56px"
          width="151px"
          bg="#161517"
          color="#FFFFFF"
          />

        }
        </Hide>
        </Flex>
        </Flex>
      <main className="contentPage">
        <Stack className="landingPage" spacing="0" height={{md:"calc(100vh-92px)", base:"calc(100vh-72px)"}}>
          <Box bg="#161517" objectFit="contain" height={{md:"calc(100vh - 272px)", base:"calc(100vh - 350px)"}} marginTop={{md:"92px", base:"72px"}}>
            <iframe width="100%"
              height="100%"
              src="https://www.youtube.com/embed/NahGbgpldZQ?autoplay=1&mute=1&loop=1&playlist=NahGbgpldZQ"
              type="video/mp4"/>
          </Box>
          <HStack height={{md: "180px"}} marginTop="0px" bg="#161517">
            <Stack
              direction={{md:"row", base:"column"}}
              justify="flex-start"
              maxWidth="100%"
              margin="auto"
              paddingX={{md: "0",base:"20px"}}
              paddingY={{md:"0", base:"32px"}}
              width={{md: "1240px"}}
            >
                <Stack>
                  <Stack
                    direction={{md:"row", base:"column"}}
                    justify="flex-start"
                    align="flex-start"
                    spacing={{md:"12px", base:"8px"}}
                  >
                    <Text

                      lineHeight={{md:"1.29", base:"18px"}}
                      fontWeight="bold"
                      fontSize={{md: "14px", base:"12px"}}
                      letterSpacing="1.5px"
                      textTransform="uppercase"
                      color="#F04E30"
                    >
                      Ville Houttu
                    </Text>
                    <Text
                      lineHeight="1.29"
                      fontWeight="bold"
                      fontSize={{md: "14px", base:"12px"}}
                      letterSpacing="1.5px"
                      textTransform="uppercase"
                      color="#F3F3F3"
                    >
                      Leadership. Business. Innovation. CEO
                    </Text>
                  </Stack>
                  <Text

                    lineHeight={{md:"1.2", base:"34px"}}
                    fontWeight="bold"
                    fontSize={{md: "40px", base:"28px"}}
                    color="#FFFFFF"
                    maxWidth="100%"
                  >
                  Become the Company Everyone Wants to Work For
                  </Text>
                </Stack>
                <Spacer />
                <Center>
                  <ScrollIntoView selector="#contact">
                    <Button
                      bg="#F04E30"
                      color="#FFFFFF"
                      height="56px"
                    >
                    Contact Ville to be Your Speaker
                    </Button>
                  </ScrollIntoView>
                  </Center>
            </Stack>
          </HStack>
        </Stack>
        <Stack
          paddingY={{md: "96px", base:"40px"}}
          justify="flex-start"
          align="center"
          background="#F4F3E9"
          maxWidth="100%"
          id="about"
        >
          <Stack
            padding={{md:"8px"}}
            justify="center"
            align="center"
            spacing={{sm:"96px", base: "40px"}}
            maxWidth="100%"
          >
            <Wrap
              justify="center"
              spacing={{base: "0", md:"72px"}}
            >
              <WrapItem width="180px" height="84px">
              <AnimationOnScroll animateIn="animate__zoomIn" delay="1">
                <Image src={incG}/>
              </AnimationOnScroll>
              </WrapItem>
              <WrapItem width="180px" height="84px">
              <AnimationOnScroll animateIn="animate__zoomIn" delay="1">
                <Image src={forbesG}/>
              </AnimationOnScroll>
              </WrapItem>
              <WrapItem width="180px" height="84px">
              <AnimationOnScroll animateIn="animate__zoomIn" delay="1">
                <Image src={inc500G}/>
              </AnimationOnScroll>
              </WrapItem>
              <WrapItem width="180px" height="84px">
              <AnimationOnScroll animateIn="animate__zoomIn" delay="1">
                <Image src={nbfG}/>
              </AnimationOnScroll>
              </WrapItem>
              <WrapItem width="180px" height="84px">
              <AnimationOnScroll animateIn="animate__zoomIn" delay="1">
                <Image src={wlG}/>
              </AnimationOnScroll>
              </WrapItem>
            </Wrap>
            <Stack
              direction={{md:"row", base:"column"}}
              justify="flex-start"
              align="center"
              spacing={{md:"101px", base:"32px"}}
              maxWidth="100%"
            >

              <Box
                borderRadius="10px"
                width="358px"
                height="544px"
              >
              <AnimationOnScroll animateIn="animate__zoomIn" delay="1">
                <Image src={villePic1}/>
              </AnimationOnScroll>
              </Box>
              <Stack justify="flex-start" align="flex-start" spacing={{md: "72px", base:"24px" }} paddingX={{base:"20px"}} maxWidth="100%">
                <Stack justify="flex-start" align="flex-start" spacing="32px" maxWidth="100%"textAlign="left">
                  <Box>
                    <Stack
                      direction="row"
                      justify="flex-start"
                      align="flex-start"
                    >
                      <Stack
                        direction="row"
                        justify="flex-start"
                        align="flex-start"
                      >
                        <Text

                          lineHeight="1.29"
                          fontWeight="bold"
                          fontSize="14px"
                          letterSpacing="1.5px"
                          textTransform="uppercase"
                          color="#F04E30"
                        >
                          Hi, I’m Ville
                        </Text>
                        <Text

                          lineHeight="1.29"
                          fontWeight="bold"
                          fontSize="14px"
                          letterSpacing="1.5px"
                          textTransform="uppercase"
                          color="#BFBFBB"
                        >
                          (VeeE-layy)
                        </Text>
                      </Stack>
                    </Stack>
                    <Text
                      paddingTop="12px"
                      lineHeight="1.25"
                      fontWeight="bold"
                      fontSize="32px"
                      color="#161517"
                      maxWidth="100%"
                    >
                      CEO | Speaker | Advisor
                    </Text>
                  </Box>
                  <Text

                    lineHeight="1.5"
                    fontWeight="regular"
                    fontSize="16px"
                    color="Annotation Palette.Markup"
                    width="588px"
                    maxWidth="100%"
                  >
                  Ville Houttu is building a movement of heroic leaders who welcome change in the workforce. He engages audiences of all sizes, challenging them to adapt revolutionary ways of making an impact in their businesses.
                    <br/><br/>Originally from Finland, he helped Vincit USA’s parent company reach 350 employees and go public on the Nasdaq First North marketplace. After the IPO, Ville moved to California to start Vincit’s local subsidiary. As a CEO and Founder, he grew the company from $0 to over $10m in only 5 years.
                    <br/><br/>In addition to being named Entrepreneur of the Year by The Greater Irvine Chamber of Commerce, FAST Company ranked his company among the Top 50 Best Workplaces for Innovators and they’ve made it to the INC 5000's Fastest Growing Companies in America list, two years running.
                    <br/><br/>His leadership has been featured by many notable organizations, such as Inc. Magazine, TechCrunch, Forbes and Adam Grant’s TED Podcast.
                  </Text>
                </Stack>
                <Stack
                  direction={{md:"row", base:"column"}}
                  justify="space-between"
                  align="flex-start"
                  spacing={{md:"95px", base:"24px"}}
                  maxWidth="100%"
                >
                  <Text
                    lineHeight="1.33"
                    fontWeight="semibold"
                    fontSize="18px"
                    color="#161517"
                    width="232px"
                    height="50px"
                    textAlign="left"
                  >
                    Want to have Ville speak at your next event?
                  </Text>
                  <ScrollIntoView selector="#contact">
                    <Button
                      bg="#F04E30"
                      color="#FFFFFF"
                      height="56px"
                    >
                    Contact Ville to be Your Speaker
                    </Button>
                    </ScrollIntoView>
                </Stack>
              </Stack>
            </Stack>

          </Stack>
        </Stack>
        <Stack
          paddingX={{md: "350px", base: "20px"}}
          paddingY={{md:"96px", base:"40px"}}
          justify="center"
          align="center"
          width="100%"
          maxWidth="100%"
          background="#FFFFFF"
          id="testimonials"
        >
          <Stack width={{md:"664px", base:"98%"}}>
            <Slider {...settings}>
              <Stack justify="flex-start" align="flex-start" spacing="12px">
              <Stack justify="flex-start" align="flex-start" spacing="24px">
                  <Text

                    lineHeight="1.29"
                    fontWeight="bold"
                    fontSize="14px"
                    letterSpacing="1.5px"
                    textTransform="uppercase"
                    color="#F04E30"
                    textAlign="left"
                  >
                    What does the audience say?
                  </Text>
                  <Text

                    lineHeight="1.6"
                    fontWeight="regular"
                    fontSize="20px"
                    color="#161517"
                    maxWidth="661px"
                    textAlign="left"
                  >
                    “Ville’s talk was insightful, and he interacted with the audience throughout the entire session. Our team was extremely pleased with both the content, presentation style, and the takeaways.”
                  </Text>
                </Stack>
                <Text

                lineHeight="1.5"
                fontWeight="semibold"
                fontSize="16px"
                color="#161517"
                textAlign="left"
              >
                Priit Liiv
              </Text>
              <Text

                lineHeight="1.5"
                fontWeight="regular"
                fontSize="16px"
                color="#737274"
                textAlign="left"
              >
                CEO at Nordic Business Forum
              </Text>
              </Stack>
              < Stack justify="flex-start" align="flex-start" spacing="12px">
              <Stack justify="flex-start" align="flex-start" spacing="24px">
                  <Text

                    lineHeight="1.29"
                    fontWeight="bold"
                    fontSize="14px"
                    letterSpacing="1.5px"
                    textTransform="uppercase"
                    color="#F04E30"
                    textAlign="left"
                  >
                    What does the audience say?
                  </Text>
                  <Text

                    lineHeight="1.6"
                    fontWeight="regular"
                    fontSize="20px"
                    color="#161517"
                    maxWidth="661px"
                    textAlign="left"
                  >
                    “Ville’s approach to removing middle management and moving them into more value-creating positions is thought-provoking and inspiring. And so are the results he has achieved on his entrepreneurial journey. I look forward to his next talk at the Executive Next Practices.”
                  </Text>
                </Stack>
                <Text

                lineHeight="1.5"
                fontWeight="semibold"
                fontSize="16px"
                color="#161517"
                textAlign="left"
              >
                Scott Hamilton
              </Text>
              <Text

                lineHeight="1.5"
                fontWeight="regular"
                fontSize="16px"
                color="#737274"
                textAlign="left"
              >
                President & CEO, ENP Institute at UCI
              </Text>
              </Stack>
              < Stack justify="flex-start" align="flex-start" spacing="12px">
              <Stack justify="flex-start" align="flex-start" spacing="24px">
                  <Text

                    lineHeight="1.29"
                    fontWeight="bold"
                    fontSize="14px"
                    letterSpacing="1.5px"
                    textTransform="uppercase"
                    color="#F04E30"
                    textAlign="left"
                  >
                    What does the audience say?
                  </Text>
                  <Text

                    lineHeight="1.6"
                    fontWeight="regular"
                    fontSize="20px"
                    color="#161517"
                    maxWidth="661px"
                    textAlign="left"
                  >
                    “Ville gave an excellent speech at our CEO Retreat in August 2022. His talk was captivating and offered us a look into the new era of leadership and how it will transform businesses in the future. I would happily recommend him to speak at your top-level business event.”
                  </Text>
                </Stack>
                <Text

                lineHeight="1.5"
                fontWeight="semibold"
                fontSize="16px"
                color="#161517"
                textAlign="left"
              >
                Steven G. Zylstra
              </Text>
              <Text

                lineHeight="1.5"
                fontWeight="regular"
                fontSize="16px"
                color="#737274"
                textAlign="left"
              >
                President & CEO, Arizona Technology Council and SciTech Institute
              </Text>
              </Stack>
              <Stack justify="flex-start" align="flex-start" spacing="12px">
                <Stack justify="flex-start" align="flex-start" spacing="24px">
                  <Text

                    lineHeight="1.29"
                    fontWeight="bold"
                    fontSize="14px"
                    letterSpacing="1.5px"
                    textTransform="uppercase"
                    color="#F04E30"
                    textAlign="left"
                  >
                    What does the audience say?
                  </Text>
                  <Text

                    lineHeight="1.6"
                    fontWeight="regular"
                    fontSize="20px"
                    color="#161517"
                    maxWidth="661px"
                    textAlign="left"
                  >
                    “I was pleased to see Ville’s CEO of the Day speech in Helsinki. The mindset was so compelling that I implemented a similar ideology into my own company, and it’s been an enormous success.”
                  </Text>
                </Stack>
                <Text

                lineHeight="1.5"
                fontWeight="semibold"
                fontSize="16px"
                color="#161517"
                textAlign="left"
              >
                Aslak de Silva
              </Text>
              <Text

                lineHeight="1.5"
                fontWeight="regular"
                fontSize="16px"
                color="#737274"
                textAlign="left"
              >
                CEO at Selfly Store
              </Text>
              </Stack>
              </Slider>
            <Flex justify="flex-start" align="flex-start">
            {/* <Spacer />
              <Stack width="172px" height="28px">
                <Stack
                  direction="row"
                  justify="flex-start"
                  align="center"
                  spacing="16px"
                  width="174px"
                >
                  <Box>
                    <Text

                      lineHeight="1.5"
                      fontWeight="semibold"
                      fontSize="16px"
                      textTransform="capitalize"
                      color="#464547"
                    >
                      01
                    </Text>
                  </Box>
                  <Box>
                    <Text

                      lineHeight="1.5"
                      fontWeight="semibold"
                      fontSize="16px"
                      textTransform="capitalize"
                      color="#CCCCCE"
                      textAlign="end"
                    >
                      03
                    </Text>
                  </Box>
                </Stack>
              </Stack> */}
              </Flex>
          </Stack>
        </Stack>
        <Stack  width="100%" maxWidth="100%" background="#161517">
          <Stack justify="center" align="center" spacing={{md:"96px", base:"48px"}}>
            <Box maxWidth="100%">
            <AnimationOnScroll animateIn="animate__zoomIn" delay="1">
              <Image src={villefeatured}/>
              </AnimationOnScroll>
            </Box>
            <Stack justify="flex-start" id="portfolio" align="center" spacing={{md:"96px", base:"48px"}}>
              <Stack justify="flex-start" align="flex-start" spacing="40px">
                <AnimationOnScroll animateIn="animate__slideInUp">
                <Text

                  lineHeight="1.29"
                  fontWeight="bold"
                  fontSize="14px"
                  letterSpacing="1.5px"
                  textTransform="uppercase"
                  color="#F04E30"
                  width="263px"
                  maxWidth="100%"
                >
                  Previously featured on
                </Text>
                </AnimationOnScroll>
                <Stack justify="flex-start" align="flex-start" spacing="16px">
                <AnimationOnScroll animateIn="animate__slideInUp" delay="1">
                  <Stack
                    direction="row"
                    justify="space-between"
                    align="center"
                    spacing="64px"
                    height={{md:"84px", base:"66px"}}
                    maxWidth="100%"
                    width={{md:"740px"}}
                  >
                    <Stack
                      direction="row"
                      justify="flex-start"
                      align="center"
                      spacing="32px"
                      alignSelf="stretch"
                      width="100%"
                    >
                      <Stack borderRadius="10px" width={{md:"180px", base:"128px"}} alignSelf="stretch">
                        <Box width={{md:"180px", base:"128px"}} height={{md:"84px", base:"66px"}}>
                          <Image src={wl}/>
                        </Box>
                      </Stack>
                      <Flex align={{md:"center"}} width="100%"flexDirection={{md:"row", base:"column"}}>
                      <Text

                        lineHeight="1.18"
                        fontWeight="semibold"
                        fontSize={{md:"22px", base:"18px"}}
                        color="#FFFFFF"
                      >
                        Worklife / TED
                      </Text>
                      <Spacer />
                    <Link target="_blank" href="https://www.vincit.com/blog/worklife">
                      <Button height={{md:"56px"}} variant="link" color="#FFFFFF" fontSize={{md:"16px", base:"14px"}} rightIcon={<FiPlay/>}>Listen Along</Button>
                    </Link>
                    </Flex>
                    </Stack>
                  </Stack>
                  </AnimationOnScroll>
                  <AnimationOnScroll animateIn="animate__slideInUp" delay="1">
                  <Stack
                    direction="row"
                    justify="space-between"
                    align="center"
                    spacing="64px"
                    height={{md:"84px", base:"66px"}}
                    maxWidth="100%"
                    width={{md:"740px"}}
                  >
                    <Stack
                      direction="row"
                      justify="flex-start"
                      align="center"
                      spacing="32px"
                      alignSelf="stretch"
                      width="100%"
                    >
                      <Stack borderRadius="10px" width={{md:"180px", base:"128px"}} alignSelf="stretch">
                        <Box width={{md:"180px", base:"128px"}} height={{md:"84px", base:"66px"}}>
                          <Image src={inc}/>
                        </Box>
                      </Stack>
                      <Flex align={{md:"center"}} width="100%"flexDirection={{md:"row", base:"column"}}>
                      <Text

                        lineHeight="1.18"
                        fontWeight="semibold"
                        fontSize={{md:"22px", base:"18px"}}
                        color="#FFFFFF"
                      >
                        Inc. Magazine
                      </Text>
                    <Spacer />
                    <Link target="_blank" href="https://www.inc.com/jeff-steen/this-founder-made-an-employee-ceo-for-a-day-it-revealed-1-thing-most-people-dont-know-about-successful-leaders.html">
                      <Button height={{md:"56px"}}variant="link" color="#FFFFFF" fontSize={{md:"16px", base:"14px"}} rightIcon={<FiArrowUpRight/>}>Read More</Button>
                      </Link>
                      </Flex>
                      </Stack>
                  </Stack>
                  </AnimationOnScroll>
                  <AnimationOnScroll animateIn="animate__slideInUp" delay="1">
                  <Stack
                    direction="row"
                    justify="space-between"
                    align="center"
                    spacing="64px"
                    height={{md:"84px", base:"66px"}}
                    maxWidth="100%"
                    width={{md:"740px"}}
                  >
                    <Stack
                      direction="row"
                      justify="flex-start"
                      align="center"
                      spacing="32px"
                      alignSelf="stretch"
                      width="100%"
                    >
                      <Stack borderRadius="10px" width={{md:"180px", base:"128px"}} alignSelf="stretch">
                        <Box width={{md:"180px", base:"128px"}} height={{md:"84px", base:"66px"}}>
                          <Image src={forbes}/>
                        </Box>
                      </Stack>
                      <Flex align={{md:"center"}} width="100%"flexDirection={{md:"row", base:"column"}}>
                      <Text

                        lineHeight="1.18"
                        fontWeight="semibold"
                        fontSize={{md:"22px", base:"18px"}}
                        color="#FFFFFF"
                      >
                        Forbes
                      </Text>
                    <Spacer/>
                    <Link target="_blank" href="https://www.forbes.com/sites/forbestechcouncil/2020/01/16/why-we-make-every-employee-ceo-for-a-day-and-why-you-may-want-to-do-the-same/?sh=2ebcf1b36ef3">
                      <Button height={{md:"56px"}} variant="link" color="#FFFFFF" fontSize={{md:"16px", base:"14px"}} rightIcon={<FiArrowUpRight/>}>Read More</Button>
                    </Link>
                    </Flex>
                    </Stack>
                  </Stack>
                  </AnimationOnScroll>
                  <AnimationOnScroll animateIn="animate__slideInUp" delay="1">
                  <Stack
                    direction="row"
                    justify="space-between"
                    align="center"
                    spacing="64px"
                    height={{md:"84px", base:"66px"}}
                    maxWidth="100%"
                    width={{md:"740px"}}
                  >
                    <Stack
                      direction="row"
                      justify="flex-start"
                      align="center"
                      spacing="32px"
                      alignSelf="stretch"
                      width="100%"
                    >
                      <Stack borderRadius="10px" width={{md:"180px", base:"128px"}} alignSelf="stretch">
                        <Box width={{md:"180px", base:"128px"}} height={{md:"84px", base:"66px"}}>
                          <Image src={inc500}/>
                        </Box>
                      </Stack>
                      <Flex align={{md:"center"}} width="100%"flexDirection={{md:"row", base:"column"}}>
                      <Text

                        lineHeight="1.18"
                        fontWeight="semibold"
                        fontSize={{md:"22px", base:"18px"}}
                        color="#FFFFFF"
                      >
                        Inc. 500
                      </Text>
                      <Spacer/>
                    <Link target="_blank" href="https://www.vincit.com/blog/what-our-inc.-5000-award-win-means-to-us-vincit">
                      <Button height={{md:"56px"}} variant="link" color="#FFFFFF" fontSize={{md:"16px", base:"14px"}}rightIcon={<FiArrowUpRight/>}>Read More</Button>
                    </Link>
                    </Flex>
                    </Stack>
                  </Stack>
                  </AnimationOnScroll>
                  <AnimationOnScroll animateIn="animate__slideInUp" delay="1">
                  <Stack
                    direction="row"
                    justify="space-between"
                    align="center"
                    spacing="64px"
                    height={{md:"84px", base:"66px"}}
                    maxWidth="100%"
                    width={{md:"740px"}}
                  >
                    <Stack
                      direction="row"
                      justify="flex-start"
                      align="center"
                      spacing="32px"
                      alignSelf="stretch"
                      width="100%"
                    >
                      <Stack borderRadius="10px" width={{md:"180px", base:"128px"}} alignSelf="stretch">
                        <Box width={{md:"180px", base:"128px"}} height={{md:"84px", base:"66px"}}>
                          <Image src={nbf}/>
                        </Box>
                      </Stack>
                      <Flex align={{md:"center"}} width="100%"flexDirection={{md:"row", base:"column"}}>
                      <Text

                        lineHeight="1.18"
                        fontWeight="semibold"
                        fontSize={{md:"22px", base:"18px"}}
                        color="#FFFFFF"
                      >
                        Nordic Business Forum
                      </Text>
                      <Spacer/>
                    <Link target="_blank" href="https://www.youtube.com/watch?v=DfMtcB2hbeE&t=17s">
                    <Button height={{md:"56px"}} variant="link" color="#FFFFFF" fontSize={{md:"16px", base:"14px"}}rightIcon={<FiPlay/>}>Watch Video</Button></Link>
                    </Flex>
                    </Stack>
                  </Stack>
                  </AnimationOnScroll>
                  <AnimationOnScroll animateIn="animate__slideInUp" delay="1">
                  <Stack
                    direction="row"
                    justify="space-between"
                    align="center"
                    spacing="64px"
                    height={{md:"84px", base:"66px"}}
                    maxWidth="100%"
                    width={{md: "740px"}}
                  >
                    <Stack
                      direction="row"
                      justify="flex-start"
                      align="center"
                      spacing="32px"
                      alignSelf="stretch"
                      width="100%"
                    >
                      <Stack borderRadius="10px" width={{md:"180px", base:"128px"}} alignSelf="stretch">
                        <Box width={{md:"180px", base:"128px"}} height={{md:"84px", base:"66px"}}>
                          <Image src={hirepower}/>
                        </Box>
                      </Stack>
                      <Flex align={{md:"center"}} width="100%"flexDirection={{md:"row", base:"column"}}>
                      <Text

                        lineHeight="1.18"
                        fontWeight="semibold"
                        fontSize={{md:"22px", base:"18px"}}
                        color="#FFFFFF"
                      >
                        Higher Power Radio
                      </Text>
                      <Spacer/>
                    <Link target="_blank" href="https://www.youtube.com/watch?v=b7tQj1E3z-A">
                    <Button height={{md:"56px"}}variant="link" color="#FFFFFF" fontSize={{md:"16px", base:"14px"}} rightIcon={<FiPlay/>}>Watch Video</Button></Link>
                    </Flex>
                    </Stack>
                  </Stack>
                  </AnimationOnScroll>
                </Stack>
              </Stack>
              <Stack mb={{base:"47px", md:"0px"}}>
                <Wrap width={{md:"1200px", base: "336px"}} spacing={{md:"144px", base: "16px"}} justify="center">

                    <WrapItem
                      borderRadius="10px"
                      width="282px"
                      height="450px"
                    >
                      <AnimationOnScroll animateIn="animate__slideInUp" delay="1">
                      <Image src={villeTall} borderRadius="5%"/>
                      </AnimationOnScroll>
                    </WrapItem>
                  <WrapItem
                    borderRadius="10px"
                    width={{md:"600px", base: "336px"}}
                  >
                    <AnimationOnScroll animateIn="animate__slideInUp" delay="1">
                      <Image src={villespeach} borderRadius="5%"/>
                      </AnimationOnScroll>
                  </WrapItem>
                </Wrap>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
        <Stack
          paddingX="120px"
          paddingTop="96px"
          paddingBottom="96px"
          justify="center"
          align="center"
          background="#F4F3E9"
          id="topics"
        >
          <Stack justify="flex-start" align="flex-start" spacing="40px">
            <Text

              lineHeight="1.21"
              fontWeight="bold"
              fontSize="28px"
              color="Brand.Dark"
            >
              Most Requested Topics
            </Text>
            <AnimationOnScroll animateIn="animate__slideInUp" delay="1">
            <Stack
                width={{md: "1200px", base: "316px"}}
                maxWidth="100%"
                borderRadius="10px"
                background="#FFFFFF"
                boxShadow="0px 0px 6px 0px rgba(0, 0, 0, 0.12)"
                borderColor="#979797"
                paddingX={{md:"48px", base: "16px"}}
                paddingY={{md:"48px", base:"24px"}}
                direction={{md:"row", base: "column"}}
                >
              <Stack
                justify="flex-start"
                align="flex-start"
                spacing={{md:"24px", base:"16px"}}
                width={{md:"558px", base:"284px"}}
                maxWidth="100%"
              >
                <Stack
                  justify="flex-start"
                  align="flex-start"
                  spacing="12px"
                  alignSelf="stretch"
                >
                  <Text

                    lineHeight="1.29"
                    fontWeight="bold"
                    fontSize="14px"
                    letterSpacing="1.5px"
                    textTransform="uppercase"
                    color="#F04E30"
                    alignSelf="stretch"
                  >
                    Topic 1
                  </Text>
                  <Text

                    lineHeight={{md: "34px", base:"26px"}}
                    fontWeight={{md:"700", base:"600"}}
                    fontSize={{md:"28px", base: "22px"}}
                    color="#161517"
                    alignSelf="stretch"
                  >
                    Leadership Lessons from the Happiest Country in The World
                  </Text>
                </Stack>
                <Text

                  lineHeight="1.56"
                  fontWeight="regular"
                  fontSize={{md: "18px", base: "16px"}}
                  color="#161517"
                  alignSelf="stretch"
                >
                  Finland has ranked No. 1 as the happiest country in the world, several years running. Simultaneously, for the first time in human history, we have five generations of employees in the workforce. Different generations expect different kinds of leadership and the Nordic leaders have been frontrunners in realizing that. In this interactive and entertaining talk, Ville shares real-life examples from his Motherland, and how the same behavior manifests in his current home country, the United States.
                </Text>
              </Stack>
              <Stack
                padding="24px"
                borderRadius="5px"
                width={{md:"490px", base:"284px"}}
                maxWidth="100%"
                background="#FAFAF7"
              >
                <Stack justify="center" align="flex-start" spacing="16px">
                  <Text

                    lineHeight="1.33"
                    fontWeight="semibold"
                    fontSize={{md:"18px", base:"16px"}}
                    color="#161517"
                  >
                    In this session you will learn:
                  </Text>
                  <Stack justify="flex-start" align="flex-start" spacing="12px">
                    <UnorderedList
                      spacing="12px"
                      lineHeight="1.5"
                      fontWeight="regular"
                      fontSize={{md:"16px", base:"14px"}}
                      color="#737274"
                      maxWidth="100%"
                    >
                      <ListItem>
                        How to engage the desire for autonomy, mastery, purpose and prosocial impact.
                      </ListItem>
                      <ListItem
                      >
                        How to optimize your employee-engagement and maximize your productivity by empowering individuals.
                      </ListItem>
                      <ListItem
                      >
                        How to attract and retain top talent with a successful company culture by leading with trust.
                      </ListItem>
                      <ListItem>
                      How to measure the results of your continuing cultural improvement.
                      </ListItem>
                    </UnorderedList>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
            </AnimationOnScroll>
            <AnimationOnScroll animateIn="animate__slideInUp" delay="1">
            <Stack breakpoint="Desktop"
                width={{md: "1200px", base: "316px"}}
                maxWidth="100%"
                borderRadius="10px"
                background="#FFFFFF"
                boxShadow="0px 0px 6px 0px rgba(0, 0, 0, 0.12)"
                borderColor="#979797"
                paddingX={{md:"48px", base: "16px"}}
                paddingY={{md:"48px", base:"24px"}}
                direction={{md:"row", base: "column"}}
                >
              <Stack
                justify="flex-start"
                align="flex-start"
                spacing={{md:"24px", base:"16px"}}
                width={{md:"558px", base:"284px"}}
                maxWidth="100%"
              >
                <Stack
                  justify="flex-start"
                  align="flex-start"
                  spacing="12px"
                  alignSelf="stretch"
                >
                  <Text

                    lineHeight="1.29"
                    fontWeight="bold"
                    fontSize="14px"
                    letterSpacing="1.5px"
                    textTransform="uppercase"
                    color="#F04E30"
                    alignSelf="stretch"
                  >
                    Topic 2
                  </Text>
                  <Text

                    lineHeight={{md: "34px", base:"26px"}}
                    fontWeight={{md:"700", base:"600"}}
                    fontSize={{md:"28px", base: "22px"}}
                    color="#161517"
                    alignSelf="stretch"
                  >
                  Why Making Every Employee CEO for a Day Will Change Business as We Know It
                  </Text>
                </Stack>
                <Text

                  lineHeight="1.56"
                  fontWeight="regular"
                  fontSize={{md: "18px", base: "16px"}}
                  color="#161517"
                  alignSelf="stretch"
                >
                  How do you build a company culture that fits? You don’t. You let your employees build it. In this innovative and provocative talk, Ville unveils his unique methodology which redefines leadership in organizations of all sizes. From untidy beanbags to unlimited budgets, Ville challenges our conventional thinking on what running a successful business looks like.
                </Text>
              </Stack>
              <Stack
                padding="24px"
                borderRadius="5px"
                width={{md:"490px", base:"284px"}}
                maxWidth="100%"
                background="#FAFAF7"
              >
                <Stack justify="center" align="flex-start" spacing="16px">
                  <Text
                    lineHeight="1.33"
                    fontWeight="semibold"
                    fontSize={{md:"18px", base:"16px"}}
                    color="#161517"
                  >
                    In this session you will learn:
                  </Text>
                  <Stack justify="flex-start" align="flex-start" spacing="12px">
                  <UnorderedList
                      spacing="12px"
                      lineHeight="1.5"
                      fontWeight="regular"
                      fontSize={{md:"16px", base:"14px"}}
                      color="#737274"
                      maxWidth="100%"
                    >
                      <ListItem>
                        How to create an organization-wide cultural change where continuous improvement isn’t just in the leaders’ minds - it’s something everyone is thinking about.
                      </ListItem>
                      <ListItem>
                        How to drastically impact company culture and employee satisfaction through individual empowerment.
                      </ListItem>
                      <ListItem>
                        What are the tools and guidelines you need to implement CEO of the Day in your team, group or an entire organization.
                      </ListItem>
                    </UnorderedList>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
            </AnimationOnScroll>
            <AnimationOnScroll animateIn="animate__slideInUp" delay="1">
            <Stack breakpoint="Desktop"
                width={{md: "1200px", base: "316px"}}
                maxWidth="100%"
                borderRadius="10px"
                background="#FFFFFF"
                boxShadow="0px 0px 6px 0px rgba(0, 0, 0, 0.12)"
                borderColor="#979797"
                paddingX={{md:"48px", base: "16px"}}
                paddingY={{md:"48px", base:"24px"}}
                direction={{md:"row", base: "column"}}
                >
              <Stack
                justify="flex-start"
                align="flex-start"
                spacing={{md:"24px", base:"16px"}}
                width={{md:"558px", base:"284px"}}
                maxWidth="100%"
              >
                <Stack
                  justify="flex-start"
                  align="flex-start"
                  spacing="12px"
                  alignSelf="stretch"
                >
                  <Text

                    lineHeight="1.29"
                    fontWeight="bold"
                    fontSize="14px"
                    letterSpacing="1.5px"
                    textTransform="uppercase"
                    color="#F04E30"
                    alignSelf="stretch"
                  >
                    Topic 3
                  </Text>
                  <Text

                    lineHeight={{md: "34px", base:"26px"}}
                    fontWeight={{md:"700", base:"600"}}
                    fontSize={{md:"28px", base: "22px"}}
                    color="#161517"
                    alignSelf="stretch"
                  >
                  How to Launch a Business in the USA with Immediate Results
                  </Text>
                </Stack>
                <Text

                  lineHeight="1.56"
                  fontWeight="regular"
                  fontSize={{md: "18px", base: "16px"}}
                  color="#161517"
                  alignSelf="stretch"
                >
                  In 2016, Ville moved from Finland to California and started his company from scratch. The company grew from $0 to over $10m in only 5 years. Fast Company ranked them among the Top-50 Best Places to Work for Innovators in the United States and they’ve made it to the INC 5000 list multiple times. In his exciting and vivid origin story, Ville explains the key things you need know to build a successful company in the United States. Because you can do the same thing.
                </Text>
              </Stack>
              <Stack
                padding="24px"
                borderRadius="5px"
                width={{md:"490px", base:"284px"}}
                maxWidth="100%"
                background="#FAFAF7"
              >
                <Stack justify="center" align="flex-start" spacing="16px">
                  <Text

                    lineHeight="1.33"
                    fontWeight="semibold"
                    fontSize={{md:"18px", base:"16px"}}
                    color="#161517"
                  >
                    In this session you will learn:
                  </Text>
                  <Stack justify="flex-start" align="flex-start" spacing="12px">
                  <UnorderedList
                      spacing="12px"
                      lineHeight="1.5"
                      fontWeight="regular"
                      fontSize={{md:"16px", base:"14px"}}
                      color="#737274"
                      maxWidth="100%"
                    >
                      <ListItem>
                      Five things you need to do to become relevant. It doesn’t matter who you know; what matters is who knows you
                      </ListItem>
                      <ListItem>
                      Why focus is not enough. How to be laser focused
                      </ListItem>
                      <ListItem>
                      What does American customers expect and how to americanize your communication
                      </ListItem>
                      <ListItem>
                      Things you don’t want to repeat; first-hand experience on common entrepreneur mistakes
                      </ListItem>
                    </UnorderedList>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
            </AnimationOnScroll>
          </Stack>
        </Stack>
        <Stack
          paddingX={{md:"196px", base:"20px"}}
          paddingY={{md:"96px", base:"40px"}}
          justify="flex-start"
          align="center"
          maxWidth="100%"
          background="#FFFFFF"
          id="connect"
        >
          <Flex direction={{md:"row", base:"column"}} justify="flex-start" align="center" gap={{md:"40px", base:"20px"}} width={{md: "1200px"}}maxWidth="1200px" >
            <Stack justify="center" align="flex-start" spacing="32px" maxW="800px">
              <Box>
              <Text

                lineHeight="1.29"
                fontWeight="bold"
                fontSize="14px"
                letterSpacing="1.5px"
                textTransform="uppercase"
                color="#F04E30"
              >
                At the end of your experience
              </Text>
                <Text
                  paddingTop="12px"
                  lineHeight="1.25"
                  fontWeight="bold"
                  fontSize={{md:"32px", base:"28px"}}
                  color="#161517"
                  maxWidth="750px"
                >
                  You will gain the tools and steps to become an impact-driven, inspirational, and empowering leader.
                </Text>
              </Box>
              <Stack justify="flex-start" align="flex-start" spacing="24px">
                <Text
                  fontWeight="bold"
                  fontSize="18px"
                >
                  Ready to get started? Download a free checklist to start the CEO of the Day program in your company:
                </Text>
                <form onSubmit={formik.handleSubmit}>
                  <VStack spacing={4}>
                    <FormControl isInvalid={formik.errors.name && formik.touched.name}>
                      <Input variant='flushed' placeholder='Name' id="name" onChange={formik.handleChange} value={formik.values.name}/>
                      <FormErrorMessage>{formik.errors.name}</FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={formik.errors.email && formik.touched.email}>
                      <Input variant='flushed' placeholder='Email' id="email" onChange={formik.handleChange} value={formik.values.email}/>
                      <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
                    </FormControl>
                    <HStack>
                      <Button
                        color="#FFFFFF"
                        bg="#F04E30"
                        fontSize={{md:"16px", base:"12px"}}
                        width="180px"
                        type="submit"
                      >
                        I NEED THIS
                      </Button>
                      <Text
                        fontWeight="light"
                        fontSize="12px"
                        maxWidth="400px"
                      >
                        By clicking ‘I need this,’ I accept the privacy terms and give my consent to receive Vincit e-mails about the latest news, resources, and updates.
                      </Text>
                    </HStack>
                    </VStack>
                </form>
              </Stack>
            </Stack>
            <Box borderRadius="10px" width={{md:"450px",base:"336px"}}maxWidth="100%">
            <AnimationOnScroll animateIn="animate__zoomIn" delay="1">
              <Image src={ville2} borderRadius="5%"/>
              </AnimationOnScroll>
            </Box>
          </Flex>
        </Stack>
      <Stack
        paddingX={{md: "197px", base:"20"}}
        paddingY={{md:"96px", base:"48px"}}
        background="#161517"
        align="center"
        id="contact"
      >
        <Stack justify="flex-start" align="flex-start" spacing={{md:"48px", base:"32px"}}>
          <Text
            lineHeight={{md:"1.13", base:"48px"}}
            fontWeight={"bold"}
            fontSize={{md:"60px", base:"40px"}}
            color="#FFFFFF"
          >
            Ready to welcome the change?
          </Text>
            <Stack maxWidth="1047px" width={{md:"1047px"}} direction={{md:"row", base:"column"}}>
            <Stack spacing="24px" maxWidth="578px">
              <Text
                fontWeight={{md:"700", base:"600"}}
                fontSize={{md:"28px", base:"22px"}}
                lineHeight="34px"
                color="#FFFFFF"
              >
                Let’s turn your business into a fantastic one.
              </Text>
              <Stack justify="flex-start" align="flex-start" spacing="24px">
                    <Stack
                      filledText="Label"
                      label="Select what topic interests you most"
                      trailingIcon
                      state="Empty"
                      width="100%"
                      height="56px"
                      maxWidth="376px"
                      justify="center"
                      align="center"
                      spacing="0px"
                    >
                      <Stack
                        direction="row"
                        justify="flex-start"
                        align="center"
                        spacing="16px"
                        height="56px"
                        alignSelf="stretch"
                      >
                        <Select
                          lineHeight="1.5"
                          fontWeight="regular"
                          fontSize="16px"
                          color="#A0A0A2"
                          height="56px"
                          flex="1"
                          variant="flushed"
                          placeholder='Select what topic interests you most'
                        >
                          <option value='Have Ville to speak at your event'>Have Ville to speak at your event</option>
                          <option value='Have Ville host a corporate workshop'>Have Ville host a corporate workshop</option>
                          <option value='Have Ville participate or host a panel'>Have Ville participate or host a panel</option>
                          <option value='other'>Other</option>
                        </Select>
                      </Stack>
                    </Stack>
                    <Stack
                      filledText="Label"
                      label="Enter your name..."
                      trailingIcon={false}
                      state="Empty"
                      width="100%"
                      height="56px"
                      maxWidth="376px"
                      justify="center"
                      align="center"
                      spacing="0px"
                    >
                      <Stack
                        direction="row"
                        justify="flex-start"
                        align="center"
                        spacing="16px"
                        height="56px"
                        alignSelf="stretch"
                      >
                        <Input

                          lineHeight="1.5"
                          fontWeight="regular"
                          fontSize="16px"
                          color="#A0A0A2"
                          height="56px"
                          flex="1"
                          variant="flushed"
                          placeholder='Enter your name...'
                        >
                        </Input>
                      </Stack>
                    </Stack>
                    <Stack
                      filledText="Label"
                      label="Enter your email..."
                      trailingIcon={false}
                      state="Empty"
                      width="100%"
                      height="56px"
                      maxWidth="376px"
                      justify="center"
                      align="center"
                      spacing="0px"
                    >
                      <Stack
                        direction="row"
                        justify="flex-start"
                        align="center"
                        spacing="16px"
                        height="56px"
                        alignSelf="stretch"
                      >
                        <Input

                          lineHeight="1.5"
                          fontWeight="regular"
                          fontSize="16px"
                          color="#A0A0A2"
                          height="56px"
                          flex="1"
                          variant="flushed"
                          placeholder='Enter your email...'
                        >
                        </Input>
                      </Stack>
                    </Stack>
                    <Stack
                      filledText="Label"
                      label="Write me a message..."
                      trailingIcon={false}
                      state="Empty"
                      width="100%"
                      height="56px"
                      maxWidth="376px"
                      justify="center"
                      align="center"
                      spacing="0px"
                    >
                      <Stack
                        direction="row"
                        justify="flex-start"
                        align="center"
                        spacing="16px"
                        height="56px"
                        alignSelf="stretch"
                      >
                        <Input

                          lineHeight="1.5"
                          fontWeight="regular"
                          fontSize="16px"
                          color="#A0A0A2"
                          height="56px"
                          flex="1"
                          variant='flushed'
                          placeholder='Write me a message...'
                        >
                        </Input>
                      </Stack>
                    </Stack>
                  <Button
                  isDisabled="true"
                    bg="#F04E30"
                    color="#FFFFFF"
                    height="56px"
                    width="170px"
                  >
                  I'm Ready
                  </Button>
            </Stack>
          </Stack>
          <Spacer />
          <Center>
          <Box
            paddingX="24px"
            paddingTop="32px"
            paddingBottom="24px"
            borderRadius="10px"
            justify="flex-start"
            align="flex-start"
            spacing="24px"
            width="336px"
            height="398px"
            maxWidth="100%"
            background="#2D2C2E"
          >
            <Stack
              justify="flex-start"
              align="flex-start"
              spacing="16px"
              alignSelf="stretch"
            >
              <Stack
                justify="center"
                align="flex-start"
                spacing="12px"
                alignSelf="stretch"
              >
                <AnimationOnScroll animateIn="animate__zoomIn" delay="1">
                  <Image src={elippse}/>
                  </AnimationOnScroll>
                <Text

                  lineHeight="1.18"
                  fontWeight="semibold"
                  fontSize="22px"
                  color="#FFFFFF"
                  alignSelf="stretch"
                >
                  Ville Houttu’s Media Kit
                </Text>
              </Stack>
              <Stack justify="flex-start" align="flex-start" alignSelf="stretch">
                <Flex
                  direction="row"
                  justify="space-between"
                  align="center"
                  spacing="4px"
                  alignSelf="stretch"
                >
                  <Text

                    lineHeight="1.5"
                    fontWeight="regular"
                    fontSize="16px"
                    color="#CCCCCE"
                  >
                    Ville’s biography
                  </Text>
                  <Spacer />
                  <IconButton variant="link" justifyContent="end" icon={<DownloadIcon  color="#CCCCCE"/>}/>
                </Flex>
                <Flex
                  direction="row"
                  justify="space-between"
                  align="center"
                  spacing="4px"
                  alignSelf="stretch"
                >
                  <Text

                    lineHeight="1.5"
                    fontWeight="regular"
                    fontSize="16px"
                    color="#CCCCCE"
                  >
                    Headshot photo
                  </Text>
                  <Spacer />
                  <IconButton variant="link" justifyContent="end" onClick={(e) => downloadFiles(e, "VilleHeadshot.jpeg")} icon={<DownloadIcon color="#CCCCCE"/>}/>
                </Flex>
                <Flex
                  direction="row"
                  justify="space-between"
                  align="center"
                  spacing="4px"
                  alignSelf="stretch"
                >
                  <Text

                    lineHeight="1.5"
                    fontWeight="regular"
                    fontSize="16px"
                    color="#CCCCCE"
                  >
                    Speaker One-Sheet
                  </Text>
                  <Spacer />
                  <IconButton variant="link" justifyContent="end" onClick={(e) => downloadFiles(e, "VilleOneSheeter.pdf")} icon={<DownloadIcon   color="#CCCCCE"/>}/>
                </Flex>
              </Stack>
            </Stack>
            <Button bg="#161517" width="288px" height="56px" marginTop="24px" color="#FFFFFF">
              Download Entire Kit
            </Button>
          </Box>
          </Center>
          </Stack>
        </Stack>
      </Stack>
      <HStack
        height="163px"
        bg="#161517"
        width="100%"
        top="0"
        alignItems="center"
      >
        <Flex
        marginX="auto"
        marginY="20px"
        width="100%"
        maxWidth="1400px"
        >
          <Box w="190px" h="100%">
            <Center w="100%" h="100%">
              <Image src={villeinitial}/>
            </Center>
          </Box>
          <Spacer />
          <Box>
          <Link target="_blank" href="https://www.linkedin.com/in/villehouttu/">
            <Center w="100%" h="100%">
            <Image margin="auto" paddingRight="20px" src={linkedIn}/>
            </Center>
          </Link>
          </Box>
          <Box>
            <Center w="100%" h="100%">
            <Link target="_blank" href="https://www.instagram.com/villehouttu/?hl=en">
            <Image margin="auto" paddingRight="30px" src={instagram}/>
          </Link>
            </Center>
          </Box>
        </Flex>
      </HStack>
      </main>
    </div>
  );
}

export default App;
